export class FieldConfigModel {
  fieldConfig: FieldConfig;
}

export class FieldConfig {
  lessonCore: Workflow;
  projectLookbackCore: Workflow;
}

export class ExtensionAttributeModel {
  copWorkflow: Workflow;
  sasbuFEWorkflow: Workflow;
  gomworkFlow: Workflow;
  nobleWorkflow: Workflow;
}

export class Workflow {
  group: GroupModel;
}

export class GroupModel {
  attribute: AttributeCore;
}

export class AttributeCore {
  feMetadata: FeMetadataAttribute;
  beMetadata: BeMetaDataAttribute;
}

export class FeMetadataAttribute {
  dataType: string;
  controlType: string;
  formLabel: string;
  gridLabel: string;
  fieldToDictateForCrossReferenceConfig?: string;
  crossReferenceConfigEnum?: string;
  referenceConfigEnum?: string;
  referenceConfigGraph?: string;
  instruction?: string;
  columnsWidth: number;
  isVisible?: boolean;
  defaultValue?: any;
  highlightWords?: boolean;
  highlightWorkflow?: any;
  highlightStatus?: any;
}

export class BeMetaDataAttribute {
  dataType: string;
  facetable: true;
  default: boolean;
  showByDefault: boolean;

}
