import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/lesson-services/common.service';
import { ParsingType } from '../../add-lesson-to-project-lookback/add-lesson-to-project-lookback.component';
import { Facet } from 'src/app/shared/models/search-options';
import { ConvertCodeToDescription } from 'src/app/shared/pipes/convert-code-to-description';

@Component({
  selector: 'app-add-facet',
  templateUrl: './add-facet.component.html',
  styleUrls: ['./add-facet.component.scss']
})
export class AddFacetComponent {

  selectedFacet: Facet;
  availableFacets: ParsingType[]=[];

  constructor(
    public dialogRef: MatDialogRef<AddFacetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private commonService: CommonService,
    public codeToDescription: ConvertCodeToDescription,
  ) {}


  ngOnInit() {
    this.availableFacets = this.data.facets;
  }

  cancel() {
    this.dialogRef.close({update: false, value: null});
  }


  
  loadFacetInfo(selectedFacet: Facet){
    let facet = new Facet();
    facet.key = selectedFacet.key;
    let fieldReferenceEnum = this.data.fieldConfig.find(
      (fieldid) => fieldid.key == selectedFacet.key)?.value?.feMetadata.referenceConfigEnum;

    facet.label = this.data.fieldConfig.find(
        (fieldid) => fieldid.key == selectedFacet.key
      )?.value?.feMetadata.formLabel;

    let referenceConfigValue = this.commonService.references[fieldReferenceEnum];

    facet.totalCount = selectedFacet.facetValues.length;
    facet.expanded = false;
    facet.facetValues = selectedFacet.facetValues.map((facet, i) => ({
      facetLabel: selectedFacet.key,
      code: facet.value,
      description: (facet.value == null || facet.value == '' || facet.value == undefined)
        ? 'undefined'
        : this.codeToDescription.transform(facet.value, referenceConfigValue),
      selected: false,
      facetIndex: 0,
      selectedIndex: i,
      count: facet.count,
      value: facet.value,
      longDescription: ""
    }));
    return facet;
  }

  submit() {

    let facet = this.loadFacetInfo(this.selectedFacet);

    this.dialogRef.close({update: true, facet: facet})
  }

  // Make labels more readable
  convertFacetLabelName(facetName){
    switch(facetName){
      case "area":
        return "Area";
      case "function":
        return "Function";
      case "asset":
        return "Asset";
      case "level2C":
        return "Asset Class";
      case "businessUnit":
        return "Business Unit";
      case "cop":
        return "CoP";
      case "discipline":
        return "Discipline";
      case "primaryImpact":
        return "Primary Impact";
      case "lessonWorkflowType":
        return "Lesson Workflow";
      case "department":
        return "Department";
      case "team":
        return "Team";
      case "relatedProcess":
        return "Related Process";
      case "lookbackCategory":
        return "Lookback Category";
      case "status":
        return "Lesson Status";
      case "subType":
        return "Sub-Type";
      case "type":
        return "Type";
      case "projectCodes":
        return "Project Lookback Name";
      case "isEnterprise":
        return "Shared Enterprise Wide";
      case "importId":
        return "Bulk Import Nickname";
      case "facility":
        return "Facility";
      case "block":
        return "Block";
      case "level5A":
        return "System";
      default:      
        return facetName;
    }
  }
    


  

}
