import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { SharedModule } from "src/app/shared/shared.module";
import { EcApproverChangeComponent } from "./dialogs/ec-approver-change/ec-approver-change.component";
import { ReportedLessonsListComponent } from "./pages/reportedlessons-list/reportedlessons-list.component";
import { ReportLessonComponent } from "./dialogs/report-lesson/report-lesson.component";
import { VerfiyReportedlessonComponent } from "./dialogs/verfiy-reportedlesson/verfiy-reportedlesson.component";
import { MsalModule } from "@azure/msal-angular";
import { ProjectLookbackComponent } from './pages/project-lookback/project-lookback.component';
import { ShareProjectLookbackComponent } from './dialogs/share-project-lookback/share-project-lookback.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { AddAdminComponent } from './dialogs/add-admin/add-admin.component';
import { CoordinatorDashboardComponent } from './pages/coordinator-dashboard/coordinator-dashboard.component';
import { AddNewManagerComponent } from './dialogs/add-new-manager/add-new-manager.component';
import { CreateEditSharedModule } from "src/app/shared/create-edit-shared.module";
import { LessonWorkflowApprovalPathComponent } from "./pages/lesson-workflow-approval-path/lesson-workflow-approval-path.component";
import { RemoveTechaprMgrComponent } from './dialogs/remove-techapr-mgr/remove-techapr-mgr.component';
import { AddLessonToProjectLookbackComponent } from "./dialogs/add-lesson-to-project-lookback/add-lesson-to-project-lookback.component";
import { AddFacetComponent } from "./dialogs/add-facet/add-facet/add-facet.component";

const appComponents = [];

@NgModule({
  declarations: [
    EcApproverChangeComponent,
    ReportedLessonsListComponent,
    ReportLessonComponent,
    VerfiyReportedlessonComponent,
    ProjectLookbackComponent,
    ShareProjectLookbackComponent,
    AutocompleteComponent,
    AddAdminComponent,
    CoordinatorDashboardComponent,
    AddNewManagerComponent,
    LessonWorkflowApprovalPathComponent,
    RemoveTechaprMgrComponent,
    AddLessonToProjectLookbackComponent,
    AddFacetComponent
  ],
  imports: [BrowserModule, SharedModule, MsalModule, CreateEditSharedModule],
  exports: [],
  providers: [],
})
export class LandingPageModule {}
