export enum ToastType {
  Success = 1,
  Alert = 2,
  Error = 3,
}

export enum CollectionAction {
  None = 0,
  Add = 1,
  Delete = 2,
  Update = 3,
}

export enum SearchScopeEnum {
  PublishedLessons = 0,
  ParticipatedLessons = 1,
  AssignedLessons = 2,
  FavoriteLessons = 3,
  ArchivedLessons = 4,
  AllLessons = 5,
  ProjectCodes = 6
}
export enum SortOrderEnum {
  Default = 0,
  RecentUpdate = 1,
  OldestUpdate = 2,
}

export enum ExtensionType {
  copWorkflow = "copWorkflow",
  gombuFEWorkflow = "gombuFEWorkflow",
  nobleWorkflow = "nobleWorkflow",
  abuFEWorkflow = "abuFEWorkflow",
  tcoFEWorkflow = "tcoFEWorkflow",
  plbWorkflow = "plbWorkflow",
  plbLessonWorkflow = "plbLessonWorkflow",
  bbuFEWorkflow = "bbuFEWorkflow",
  sasbuFEWorkflow = "sasbuFEWorkflow",
  singaporeFEWorkflow = "singaporeFEWorkflow",
  mauaFEWorkflow = "mauaFEWorkflow",
  oakpointFEWorkflow = "oakpointFEWorkflow",
  gonfrevilleFEWorkflow = "gonfrevilleFEWorkflow",
  rbuFEWorkflow = "rbuFEWorkflow",
  cppFEWorkflow = "cppFEWorkflow"
}

export enum FieldConfigStatusValues {
  visible = "visibility",
  required = "required",
  editable = "editability",
  readOnly = "readOnly",
  exportable = "exportability",
}

export enum DataTypeConstants {
  string = "string",
  stringArray = "string[]=[]",
  date = "Date",
  number = "number",
  boolean = "boolean",
  userObj = "User",
  userArray = "User[]",
  lessonCommentArray = "LessonComment[]",
}

export enum Statuses {
  //PLB as well
  draft = "draft",
  QAReview = "llreview",
  InTechnicalReview = "inTechnicalReview",
  InSmeReview = "inSmeReview",
  InEcReview = "inEcReview",
  InIpReview = "inIpReview",
  InIPECReview = "inIPECReview",
  InlegalReview = "inlegalReview",
  ReviewCompleted = "reviewCompleted",
  Publish = "publish",
  Review = "review",
  Completed = "completed",
  Approved = "approved",
  Rejected = "rejected",
  inAdminReview = "inAdminReview",
  Active = "active"
}

export enum Personas {
  manager = "manager",
  approverTechnical = "approverTechnical",
  approverTechnicals = "approverTechnicals",
  approverSMEs = "approverSMEs",
  approverEC = "approverEC",
  approverIP = "approverIP",
  approverLegal = "approverLegal",
  submitter = "submitter",
  pointOfContact = "pointOfContact",
  projectAdmins = "projectAdmins"
}

export enum ActionTypes {
  SetValueFromGraphDropwdown = "setValueFromGraphDropwdown",
  CopyValueFromField = "copyValueFromField",
  SetValue = "setValue",
}

export enum AppRoles {
  ELLMGR = "ELL-MGR",
  ELLTECHAPR = "ELL-TECHAPR",
  ELLECAPR = "ELLECAPR",
  ELLIPAPR = "ELLIPAPR",
  BLLMGRGOMBUFE = "BLL-MGR-GOMBU-FE",
  BLLTECHAPRGOMBUFE = "BLL-TECHAPR-GOMBU-FE",
  DEVADMIN = "DEVELOPER-ADMIN",
  SUPERADMINUI = "SUPERADMIN-UI",
}

export enum LogEventsActions {
  UniqueVisits = "unique visits",
  LLviews = "LL views",
  UniqueSearches = "unique searches",
  SaveOrSubmitLesson = "save or submit lesson", //previusly it was saveLesson
  ExportSearchResults = "export search results",
}
